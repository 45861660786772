'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Format = require("bs-platform/lib/js/format.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");

var root = Css.style(/* :: */[
      Css.maxWidth(Css.px(1024)),
      /* :: */[
        Css.margin2(Css.zero, Css.auto),
        /* [] */0
      ]
    ]);

var entries = Css.style(/* :: */[
      Css.border(Css.zero, Css.solid, Colors$Paulshen.primary475),
      /* :: */[
        Css.borderTopWidth(Css.px(1)),
        /* [] */0
      ]
    ]);

var entry = Css.style(/* :: */[
      Css.padding2(Css.px(16), Css.zero),
      /* :: */[
        Css.border(Css.zero, Css.solid, Colors$Paulshen.primary475),
        /* :: */[
          Css.borderBottomWidth(Css.px(1)),
          /* :: */[
            Css.display(Css.flexBox),
            /* :: */[
              Css.alignItems(Css.center),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var entryDate = Css.style(/* :: */[
      Css.fontFamily(Fonts$Paulshen.mono),
      /* :: */[
        Css.width(Css.px(128)),
        /* [] */0
      ]
    ]);

var entryTitle = Css.style(/* :: */[
      Css.fontSize(Css.px(24)),
      /* [] */0
    ]);

var linkBlurb = Css.style(/* :: */[
      Css.marginLeft(Css.px(16)),
      /* [] */0
    ]);

var photoPreview = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.backgroundSize(Css.cover),
        /* :: */[
          Css.backgroundPosition(Css.center),
          /* :: */[
            Css.opacity(0.5),
            /* :: */[
              Css.margin4(Css.px(-8), Css.zero, Css.px(-8), Css.px(32)),
              /* :: */[
                Css.unsafe("alignSelf", "stretch"),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  root: root,
  entries: entries,
  entry: entry,
  entryDate: entryDate,
  entryTitle: entryTitle,
  linkBlurb: linkBlurb,
  photoPreview: photoPreview
};

var entries$1 = /* array */[
  /* Link */Block.__(0, [{
        date: new Date("2020-01-03"),
        title: "The Joy of Movement",
        url: "http://kellymcgonigal.com/move",
        blurb: "Hello"
      }]),
  /* Photo */Block.__(1, [{
        time: new Date("2015-10-12T15:20Z"),
        url: "http://s3.bypaulshen.com/photos/iceland/DSCF3966.jpg"
      }])
];

function formatMonth(time) {
  var match = time.getUTCMonth();
  if (match < 6) {
    if (match < 3) {
      if (match !== 0) {
        if (match !== 1) {
          if (match !== 2) {
            return "";
          } else {
            return "Mar";
          }
        } else {
          return "Feb";
        }
      } else {
        return "Jan";
      }
    } else if (match !== 3) {
      if (match !== 4) {
        if (match !== 5) {
          return "";
        } else {
          return "Jun";
        }
      } else {
        return "May";
      }
    } else {
      return "Apr";
    }
  } else if (match < 9) {
    if (match !== 6) {
      if (match !== 7) {
        if (match !== 8) {
          return "";
        } else {
          return "Sep";
        }
      } else {
        return "Aug";
      }
    } else {
      return "Jul";
    }
  } else if (match !== 9) {
    if (match !== 10) {
      if (match !== 11) {
        return "";
      } else {
        return "Dec";
      }
    } else {
      return "Nov";
    }
  } else {
    return "Oct";
  }
}

function formatTime(time) {
  var hours = time.getUTCHours() | 0;
  var match = hours >= 12;
  return Curry._1(Format.sprintf(/* Format */[
                  /* Formatting_gen */Block.__(18, [
                      /* Open_box */Block.__(1, [/* Format */[
                            /* End_of_format */0,
                            ""
                          ]]),
                      /* Int */Block.__(4, [
                          /* Int_i */3,
                          /* Lit_padding */Block.__(0, [
                              /* Zeros */2,
                              2
                            ]),
                          /* No_precision */0,
                          /* Formatting_lit */Block.__(17, [
                              /* Close_box */0,
                              /* End_of_format */0
                            ])
                        ])
                    ]),
                  "@[%02i@]"
                ]), (hours + 11 | 0) % 12 + 1 | 0) + (":" + (Curry._1(Format.sprintf(/* Format */[
                      /* Formatting_gen */Block.__(18, [
                          /* Open_box */Block.__(1, [/* Format */[
                                /* End_of_format */0,
                                ""
                              ]]),
                          /* Int */Block.__(4, [
                              /* Int_i */3,
                              /* Lit_padding */Block.__(0, [
                                  /* Zeros */2,
                                  2
                                ]),
                              /* No_precision */0,
                              /* Formatting_lit */Block.__(17, [
                                  /* Close_box */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ]),
                      "@[%02i@]"
                    ]), time.getUTCMinutes() | 0) + (" " + (
                match ? "PM" : "AM"
              ))));
}

function formatDate(time) {
  return formatMonth(time) + (" " + Curry._1(Format.sprintf(/* Format */[
                    /* Formatting_gen */Block.__(18, [
                        /* Open_box */Block.__(1, [/* Format */[
                              /* End_of_format */0,
                              ""
                            ]]),
                        /* Int */Block.__(4, [
                            /* Int_i */3,
                            /* Lit_padding */Block.__(0, [
                                /* Zeros */2,
                                2
                              ]),
                            /* No_precision */0,
                            /* Formatting_lit */Block.__(17, [
                                /* Close_box */0,
                                /* End_of_format */0
                              ])
                          ])
                      ]),
                    "@[%02i@]"
                  ]), time.getUTCDate() | 0));
}

var make = React.memo((function (Props) {
        var onMouseEnter = Props.onMouseEnter;
        var onMouseLeave = Props.onMouseLeave;
        return React.createElement("div", {
                    className: entries
                  }, entries$1.map((function (entry$1) {
                          if (entry$1.tag) {
                            var photo = entry$1[0];
                            return React.createElement("div", {
                                        key: photo.url,
                                        className: entry,
                                        onMouseEnter: (function (param) {
                                            return Curry._1(onMouseEnter, entry$1);
                                          }),
                                        onMouseLeave: (function (param) {
                                            return Curry._1(onMouseLeave, entry$1);
                                          })
                                      }, React.createElement("div", {
                                            className: entryDate
                                          }, formatDate(photo.time)), React.createElement("div", {
                                            className: entryTitle
                                          }, "Photo @ " + formatTime(photo.time)), React.createElement("div", {
                                            className: photoPreview,
                                            style: {
                                              backgroundImage: "url(" + (photo.url + ")")
                                            }
                                          }));
                          } else {
                            var link = entry$1[0];
                            var match = link.blurb;
                            return React.createElement("div", {
                                        key: link.url,
                                        className: entry,
                                        onMouseEnter: (function (param) {
                                            return Curry._1(onMouseEnter, entry$1);
                                          }),
                                        onMouseLeave: (function (param) {
                                            return Curry._1(onMouseLeave, entry$1);
                                          })
                                      }, React.createElement("div", {
                                            className: entryDate
                                          }, formatDate(link.date)), React.createElement("div", {
                                            className: entryTitle
                                          }, link.title), match !== undefined ? React.createElement("div", {
                                              className: linkBlurb
                                            }, match) : null);
                          }
                        })));
      }));

var EntryTable = {
  make: make
};

var root$1 = Css.style(/* :: */[
      Css.backgroundColor(Colors$Paulshen.primary500),
      /* :: */[
        Css.position(Css.absolute),
        /* :: */[
          Css.pointerEvents(Css.none),
          /* [] */0
        ]
      ]
    ]);

var hidden = Css.style(/* :: */[
      Css.display(Css.none),
      /* [] */0
    ]);

var photo = Css.style(/* :: */[
      Css.maxWidth(Css.px(768)),
      /* :: */[
        Css.maxHeight(Css.px(768)),
        /* [] */0
      ]
    ]);

var linkBlurb$1 = Css.style(/* :: */[
      Css.backgroundColor(Colors$Paulshen.primary490),
      /* :: */[
        Css.padding(Css.px(32)),
        /* :: */[
          Css.width(Css.px(384)),
          /* [] */0
        ]
      ]
    ]);

var Styles$1 = {
  root: root$1,
  hidden: hidden,
  photo: photo,
  linkBlurb: linkBlurb$1
};

function LogPage$EntryPreviewPopout(Props) {
  var previewEntry = Props.previewEntry;
  var match = React.useState((function () {
          return false;
        }));
  var setShown = match[1];
  var rootRef = React.useRef(null);
  React.useEffect((function () {
          var onMouseMove = function (e) {
            var rootElement = Belt_Option.getExn(Caml_option.nullable_to_opt(rootRef.current));
            rootElement.style.setProperty("left", String(e.pageX + 16 | 0) + "px", "");
            rootElement.style.setProperty("top", String(e.pageY - 16 | 0) + "px", "");
            return /* () */0;
          };
          Curry._1(setShown, (function (param) {
                  return true;
                }));
          window.addEventListener("mousemove", onMouseMove);
          return (function (param) {
                    window.removeEventListener("mousemove", onMouseMove);
                    return /* () */0;
                  });
        }), ([]));
  var tmp;
  if (previewEntry.tag) {
    tmp = React.createElement("div", undefined, React.createElement("img", {
              className: photo,
              src: previewEntry[0].url
            }));
  } else {
    var match$1 = previewEntry[0].blurb;
    tmp = match$1 !== undefined ? React.createElement("div", {
            className: linkBlurb$1
          }, match$1) : null;
  }
  return React.createElement("div", {
              ref: rootRef,
              className: Cn.make(/* :: */[
                    root$1,
                    /* :: */[
                      Cn.ifTrue(hidden, !match[0]),
                      /* [] */0
                    ]
                  ])
            }, tmp);
}

var EntryPreviewPopout = {
  Styles: Styles$1,
  make: LogPage$EntryPreviewPopout
};

function LogPage(Props) {
  var match = React.useState((function () {
          return ;
        }));
  var setPreviewEntry = match[1];
  var previewEntry = match[0];
  var previewEntryRef = React.useRef(previewEntry);
  React.useLayoutEffect((function () {
          previewEntryRef.current = previewEntry;
          return ;
        }), /* array */[previewEntry]);
  var onMouseEnter = React.useCallback((function (entry) {
          return Curry._1(setPreviewEntry, (function (param) {
                        return entry;
                      }));
        }), ([]));
  var onMouseLeave = React.useCallback((function (entry) {
          if (Caml_obj.caml_equal(entry, previewEntryRef.current)) {
            return Curry._1(setPreviewEntry, (function (param) {
                          return ;
                        }));
          } else {
            return 0;
          }
        }), ([]));
  return React.createElement(Page$Paulshen.make, {
              children: null,
              title: "Draw"
            }, React.createElement("div", {
                  className: root
                }, React.createElement(Text$Paulshen.MonoHeader.make, {
                      children: "Log",
                      size: /* S2 */18559
                    }), React.createElement(make, {
                      onMouseEnter: onMouseEnter,
                      onMouseLeave: onMouseLeave
                    })), previewEntry !== undefined ? React.createElement(LogPage$EntryPreviewPopout, {
                    previewEntry: previewEntry
                  }) : null);
}

var make$1 = LogPage;

var slug = "/log";

var $$default = LogPage;

exports.Styles = Styles;
exports.entries = entries$1;
exports.formatMonth = formatMonth;
exports.formatTime = formatTime;
exports.formatDate = formatDate;
exports.EntryTable = EntryTable;
exports.EntryPreviewPopout = EntryPreviewPopout;
exports.make = make$1;
exports.slug = slug;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* root Not a pure module */
